@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@shared/design-system/styles/initial.css';

@layer base {
  :root {
    /* Global */

    --max-content: 1296px;

    /* Colors */
    --color-neutral-0: 0 0% 7%; /* #111111 */
    --color-neutral-50: 0 0% 27%; /* #454545 */
    --color-neutral-100: 0 0% 60%; /* #999999 */
    --color-neutral-200: 0 0% 83%; /* #d4d4d4 */
    --color-neutral-300: 0 0% 92%; /* #ebebeb */
    --color-neutral-400: 0 0% 96%; /* #f5f5f5 */
    --color-neutral-500: 0 0% 99%; /* #fcfcfc */
    --color-neutral-600: 0 0% 100%; /* #ffffff */

    --color-orange-50: #fff7ed;
    --color-orange-100: #ffedd4;
    --color-orange-200: #ffd6a7;
    --color-orange-600: #ff6900;

    --color-blue-100: #fafbfc;

    --color-green-100: 143, 47%, 93%; /* #e6f6ec */
    --color-green-600: 142 100% 33%; /* #00A63E */

    --color-red-600: 357 100% 45%; /* #E7000B */

    /* Fonts */
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 2rem;
    --font-size-4xl: 2.5rem;
    --font-size-5xl: 3rem;
    --font-size-6xl: 3.5rem;
    --font-size-7xl: 4rem;

    /* Font weights */
    --font-weight-medium: 500;

    /* Borders */
    --border-weight-default: 1.5px;

    /* Button */
    --btn-text: hsl(var(--font-size-md));
    --btn-font-family: var(--font-primary);
    --btn-font-weight: var(--font-weight-medium);
    --btn-primary-bg: hsl(var(--color-neutral-0));
    --btn-primary-hover: hsl(var(--color-neutral-50));

    --btn-secondary-bg: var(--color-orange-600);
    --btn-secondary-text: hsl(var(--color-neutral-600));
    --btn-secondary-hover: var(--color-orange-600);
    --btn-secondary-active: var(--color-orange-600);

    --btn-h-sm: 35px;
    --btn-px-sm: 1rem;

    --btn-outline-border-color: hsl(var(--color-neutral-0) / 0.05);

    /* Card */
    --card-radius: 2.5rem;
    --card-border: none;
    --card-shadow: none;
  }
}
